import { User } from "firebase/auth";
import { useCallback } from "react";
import { useDispatch } from "state/hooks";
import { setCurrentUser } from "state/slices/session/sessionSlice";
import { useOnAuthStateChanged } from "./useOnAuthStateChanged";

export interface UseUpdateSessionStateOnAuthChange {
  (): void;
}

/**
 * Automatically updates the redux store's `sessionState` when there are changes to the
 * firebase auth user state or the auth token refreshes
 */
export const useUpdateSessionStateOnAuthChange: UseUpdateSessionStateOnAuthChange =
  () => {
    const dispatch = useDispatch();
    const handler = useCallback(
      async (user: User | null): Promise<void> => {
        dispatch(
          setCurrentUser({
            currentUser: user
              ? {
                  uid: user.uid,
                  email: user.email || null,
                  token: await user.getIdToken(),
                }
              : null,
          })
        );
      },
      [dispatch]
    );

    useOnAuthStateChanged(handler);
  };
