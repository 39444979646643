import { createTheme } from "@mui/material";

// We need this so we can add custom properties to the palette object in our
// theme.
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    grays: Palette["primary"];
    icons: Palette["primary"];
  }
  interface PaletteOptions {
    grays: PaletteOptions["primary"];
    icons: PaletteOptions["primary"];
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
  palette: {
    // These colors are taken from our mocks. Main is the background
    // color for cards, dark is the background color from the items in
    // the navbar, and light is the border colors.
    grays: {
      main: "#f5f5f5",
      dark: "#ebebeb",
      light: "#e8e8e8",
    },
    icons: {
      main: "#242424",
    },
  },
});
