import { Box, Theme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";
import { Header } from ".";

/**
 * Props for the page layout.
 * userName - An optional string with the username.
 * children - Optional elements to render within the main content of the
 * container
 */
export interface PageLayoutProps {
  userName?: string;
  children?: ReactNode;
}

const LayoutContainer = styled(Box)(() => ({
  // For now we don't need any further grid stylings, but this will
  // become more complex as we add more elements such as the sidebar.
  display: "grid",
  gridTemplateRows: "auto 1fr",
}));

const MainContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

/**
 * Page Layout wrapper component.
 * @param props The PageLayoutProps to pass to the component.
 * @returns The page layout wrapper component.
 */
export function PageLayout({ userName, children }: PageLayoutProps) {
  const isSmallScreenSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <LayoutContainer>
      <Header userName={userName} isSmallScreenSize={isSmallScreenSize} />
      <MainContentContainer component="main">{children}</MainContentContainer>
    </LayoutContainer>
  );
}
