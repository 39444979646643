import { configureStore } from "@reduxjs/toolkit";
import { buildAPI } from "../api/buildAPI";
import { sessionReducer } from "./slices/session/sessionSlice";

// this is required to apply the build api endpoints tot eh build api
import "../services/buildAPIService";

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    [buildAPI.reducerPath]: buildAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(buildAPI.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
