import { LoadingSpinner } from "components/LoadingSpinner";
import * as React from "react";
import { Route, Routes } from "react-router";
import { getAuth } from "firebase/auth";
import { AuthPage } from "AuthPage";

const ProjectRouter = React.lazy(() =>
  import("pages/project/ProjectRouter").then((module) => ({
    default: module.ProjectRouter,
  }))
);

const ButtonsPage = React.lazy(() =>
  import("pages/buttons/ButtonsPage").then((module) => ({
    default: module.ButtonsPage,
  }))
);

const ErrorPage = React.lazy(() =>
  import("pages/error/ErrorPage").then((mod) => ({ default: mod.ErrorPage }))
);

const FourOhFour = React.lazy(() =>
  import("pages/404").then((mod) => ({ default: mod.FourOhFour }))
);

export interface AppRoutesProps {}

export const AppRoutes: React.FunctionComponent<AppRoutesProps> = () => {
  return (
    <Routes>
      <Route
        path="/project/*"
        element={
          <React.Suspense fallback={<LoadingSpinner />}>
            <ProjectRouter />
          </React.Suspense>
        }
      />
      <Route
        path="/buttons/:buildProjectId"
        element={
          <React.Suspense fallback={<LoadingSpinner />}>
            <ButtonsPage />
          </React.Suspense>
        }
      />
      <Route
        path="/error/*"
        element={
          <React.Suspense fallback={<LoadingSpinner />}>
            <ErrorPage />
          </React.Suspense>
        }
      />
      <Route path="/yes" element={<AuthPage />} />
      <Route
        path="/404"
        element={
          <React.Suspense fallback={<LoadingSpinner />}>
            <FourOhFour />
          </React.Suspense>
        }
      />
    </Routes>
  );
};
