import React from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { PageLayout } from "components/layout";
import { theme } from "./utils/styles/theme";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "routes";
import { useUpdateSessionStateOnAuthChange } from "utils/hooks/useUpdateSessionStateOnAuthChange";

function App() {
  useUpdateSessionStateOnAuthChange();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <BrowserRouter>
          {/*
           * TODO: Update this username later with the proper company name
           * for the logged in contractor
           */}
          <PageLayout userName="Test Company">
            <AppRoutes />
          </PageLayout>
        </BrowserRouter>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
