import { buildAPI as api } from "../api/buildAPI";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    liisaControllerHealthcheck: build.query<
      LiisaControllerHealthcheckApiResponse,
      LiisaControllerHealthcheckApiArg
    >({
      query: () => ({ url: `/v1/healthcheck` }),
    }),
    accountManagersControllerCreate: build.mutation<
      AccountManagersControllerCreateApiResponse,
      AccountManagersControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/account-managers`,
        method: "POST",
        body: queryArg.createAccountManagerDto,
      }),
    }),
    accountManagersControllerFindAll: build.query<
      AccountManagersControllerFindAllApiResponse,
      AccountManagersControllerFindAllApiArg
    >({
      query: () => ({ url: `/v1/account-managers` }),
    }),
    accountManagersControllerFindOne: build.query<
      AccountManagersControllerFindOneApiResponse,
      AccountManagersControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/v1/account-managers/${queryArg.id}` }),
    }),
    accountManagersControllerUpdate: build.mutation<
      AccountManagersControllerUpdateApiResponse,
      AccountManagersControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/account-managers/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateAccountManagerDto,
      }),
    }),
    accountManagersControllerRemove: build.mutation<
      AccountManagersControllerRemoveApiResponse,
      AccountManagersControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/account-managers/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    clientProfilesControllerFindAll: build.query<
      ClientProfilesControllerFindAllApiResponse,
      ClientProfilesControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/client-profiles/list`,
        params: {
          fromdt: queryArg.fromdt,
          todt: queryArg.todt,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          testAccts: queryArg.testAccts,
          isDesigner: queryArg.isDesigner,
          status: queryArg.status,
          sortDirection: queryArg.sortDirection,
          search: queryArg.search,
        },
      }),
    }),
    contractorsControllerCreate: build.mutation<
      ContractorsControllerCreateApiResponse,
      ContractorsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contractors`,
        method: "POST",
        body: queryArg.createContractorDto,
      }),
    }),
    contractorsControllerFindAll: build.query<
      ContractorsControllerFindAllApiResponse,
      ContractorsControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contractors`,
        params: {
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          sortDirection: queryArg.sortDirection,
          search: queryArg.search,
        },
      }),
    }),
    contractorsControllerFindOne: build.query<
      ContractorsControllerFindOneApiResponse,
      ContractorsControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/v1/contractors/${queryArg.id}` }),
    }),
    contractorsControllerUpdate: build.mutation<
      ContractorsControllerUpdateApiResponse,
      ContractorsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contractors/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateContractorDto,
      }),
    }),
    contractorsControllerRemove: build.mutation<
      ContractorsControllerRemoveApiResponse,
      ContractorsControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contractors/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    contractorsControllerGetLeadsByContractor: build.query<
      ContractorsControllerGetLeadsByContractorApiResponse,
      ContractorsControllerGetLeadsByContractorApiArg
    >({
      query: (queryArg) => ({ url: `/v1/contractors/${queryArg.id}/leads` }),
    }),
    leadsControllerCreate: build.mutation<
      LeadsControllerCreateApiResponse,
      LeadsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/leads`,
        method: "POST",
        body: queryArg.createLeadDto,
      }),
    }),
    leadsControllerFindAll: build.query<
      LeadsControllerFindAllApiResponse,
      LeadsControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/leads`,
        params: {
          sortDirection: queryArg.sortDirection,
          status: queryArg.status,
        },
      }),
    }),
    leadsControllerFindOne: build.query<
      LeadsControllerFindOneApiResponse,
      LeadsControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/v1/leads/${queryArg.id}` }),
    }),
    leadsControllerUpdate: build.mutation<
      LeadsControllerUpdateApiResponse,
      LeadsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/leads/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateLeadDto,
      }),
    }),
    leadsControllerRemove: build.mutation<
      LeadsControllerRemoveApiResponse,
      LeadsControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/leads/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    buildProjectsControllerCreate: build.mutation<
      BuildProjectsControllerCreateApiResponse,
      BuildProjectsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/build-projects`,
        method: "POST",
        body: queryArg.createBuildProjectDto,
      }),
    }),
    buildProjectsControllerFindAll: build.query<
      BuildProjectsControllerFindAllApiResponse,
      BuildProjectsControllerFindAllApiArg
    >({
      query: () => ({ url: `/v1/build-projects` }),
    }),
    buildProjectsControllerFindOne: build.query<
      BuildProjectsControllerFindOneApiResponse,
      BuildProjectsControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/v1/build-projects/${queryArg.id}` }),
    }),
    buildProjectsControllerUpdate: build.mutation<
      BuildProjectsControllerUpdateApiResponse,
      BuildProjectsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/build-projects/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateBuildProjectDto,
      }),
    }),
    buildProjectsControllerRemove: build.mutation<
      BuildProjectsControllerRemoveApiResponse,
      BuildProjectsControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/build-projects/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    buildProjectsControllerGetLeadsByBuildProject: build.query<
      BuildProjectsControllerGetLeadsByBuildProjectApiResponse,
      BuildProjectsControllerGetLeadsByBuildProjectApiArg
    >({
      query: (queryArg) => ({ url: `/v1/build-projects/${queryArg.id}/leads` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as buildAPIService };
export type LiisaControllerHealthcheckApiResponse = /** status 200  */ string;
export type LiisaControllerHealthcheckApiArg = void;
export type AccountManagersControllerCreateApiResponse =
  /** status 201 The account manager has been created. */ AccountManager;
export type AccountManagersControllerCreateApiArg = {
  createAccountManagerDto: CreateAccountManagerDto;
};
export type AccountManagersControllerFindAllApiResponse =
  /** status 200  */ AccountManager[];
export type AccountManagersControllerFindAllApiArg = void;
export type AccountManagersControllerFindOneApiResponse =
  /** status 200 The account manager record */ AccountManager;
export type AccountManagersControllerFindOneApiArg = {
  id: string;
};
export type AccountManagersControllerUpdateApiResponse =
  /** status 200  */ StatusResponseDto;
export type AccountManagersControllerUpdateApiArg = {
  id: string;
  updateAccountManagerDto: UpdateAccountManagerDto;
};
export type AccountManagersControllerRemoveApiResponse =
  /** status 200  */ StatusResponseDto;
export type AccountManagersControllerRemoveApiArg = {
  id: string;
};
export type ClientProfilesControllerFindAllApiResponse =
  /** status 200  */ ClientProfile[];
export type ClientProfilesControllerFindAllApiArg = {
  /** From date in ISO string format (`new Date().toISOString()`). Defaults to 3 months ago. */
  fromdt?: string;
  /** To date in ISO string format (`new Date().toISOString()`). Defaults to now. */
  todt?: string;
  /** For pagination purposes. The page number to be fetched. */
  page?: string;
  /** Number of results to return per page. */
  pageSize?: string;
  /** Include test accounts. */
  testAccts?: string;
  /** Include designer accounts. */
  isDesigner?: string;
  /** Maps to Status dropdown options. */
  status?:
    | "materialsRecieved"
    | "reviewDesign"
    | "clientReview"
    | "drafting"
    | "designerActionable"
    | "feedbackRecieved";
  /** 1 for ASC and -1 for DESC. */
  sortDirection?: "1" | "-1";
  /** String that user is searching for. Valid entries include firstName, lastName, email, and address. */
  search?: string;
};
export type ContractorsControllerCreateApiResponse = /** status 201  */ object;
export type ContractorsControllerCreateApiArg = {
  createContractorDto: CreateContractorDto;
};
export type ContractorsControllerFindAllApiResponse =
  /** status 200  */ Contractor[];
export type ContractorsControllerFindAllApiArg = {
  /** For pagination purposes. The page number to be fetched. */
  page?: string;
  /** Number of results to return per page. */
  pageSize?: string;
  /** 1 for ASC and -1 for DESC. */
  sortDirection?: "1" | "-1";
  /** String that user is searching for. Valid entries include firstName, lastName, email, & businessName. */
  search?: string;
};
export type ContractorsControllerFindOneApiResponse =
  /** status 200  */ Contractor;
export type ContractorsControllerFindOneApiArg = {
  id: string;
};
export type ContractorsControllerUpdateApiResponse = /** status 200  */ object;
export type ContractorsControllerUpdateApiArg = {
  id: string;
  updateContractorDto: UpdateContractorDto;
};
export type ContractorsControllerRemoveApiResponse =
  /** status 200  */ StatusResponseDto;
export type ContractorsControllerRemoveApiArg = {
  id: string;
};
export type ContractorsControllerGetLeadsByContractorApiResponse =
  /** status 200  */ LeadDto[];
export type ContractorsControllerGetLeadsByContractorApiArg = {
  id: string;
};
export type LeadsControllerCreateApiResponse = /** status 201  */ LeadDto;
export type LeadsControllerCreateApiArg = {
  createLeadDto: CreateLeadDto;
};
export type LeadsControllerFindAllApiResponse = /** status 200  */ LeadDto[];
export type LeadsControllerFindAllApiArg = {
  /** 1 for ASC and -1 for DESC. */
  sortDirection?: "1" | "-1";
  /** Maps to Status dropdown options. */
  status?:
    | "Projects for Prospect"
    | "Ready for Pro Match"
    | "Waiting for Build Confirm"
    | "Sent for Review"
    | "Intro Sent"
    | "Site Walk Scheduled"
    | "Site Walk Completed"
    | "Revisions Requested"
    | "Estimate Delivered"
    | "Contract Signed"
    | "Project Started"
    | "Project Completed"
    | "Ready for Invoice"
    | "Invoice Sent"
    | "Invoice Paid"
    | "Client Unresponsive"
    | "Lost"
    | "Hold";
};
export type LeadsControllerFindOneApiResponse = /** status 200  */ LeadDto;
export type LeadsControllerFindOneApiArg = {
  id: string;
};
export type LeadsControllerUpdateApiResponse =
  /** status 200  */ StatusResponseDto;
export type LeadsControllerUpdateApiArg = {
  id: string;
  updateLeadDto: UpdateLeadDto;
};
export type LeadsControllerRemoveApiResponse =
  /** status 200  */ StatusResponseDto;
export type LeadsControllerRemoveApiArg = {
  id: string;
};
export type BuildProjectsControllerCreateApiResponse =
  /** status 201  */ object;
export type BuildProjectsControllerCreateApiArg = {
  createBuildProjectDto: CreateBuildProjectDto;
};
export type BuildProjectsControllerFindAllApiResponse =
  /** status 200  */ BuildProjectDto[];
export type BuildProjectsControllerFindAllApiArg = void;
export type BuildProjectsControllerFindOneApiResponse =
  /** status 200  */ BuildProjectDto;
export type BuildProjectsControllerFindOneApiArg = {
  id: string;
};
export type BuildProjectsControllerUpdateApiResponse =
  /** status 200  */ object;
export type BuildProjectsControllerUpdateApiArg = {
  id: string;
  updateBuildProjectDto: UpdateBuildProjectDto;
};
export type BuildProjectsControllerRemoveApiResponse =
  /** status 200  */ StatusResponseDto;
export type BuildProjectsControllerRemoveApiArg = {
  id: string;
};
export type BuildProjectsControllerGetLeadsByBuildProjectApiResponse =
  /** status 200  */ LeadDto[];
export type BuildProjectsControllerGetLeadsByBuildProjectApiArg = {
  id: string;
};
export type AccountManager = {};
export type CreateAccountManagerDto = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  userId?: string | null;
  downloadURL?: string | null;
  zendeskSellUid?: string | null;
};
export type StatusResponseDto = {
  message: string;
  statusCode: number;
};
export type UpdateAccountManagerDto = {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  userId?: string | null;
  downloadURL?: string | null;
  zendeskSellUid?: string | null;
};
export type ClientProfile = {};
export type CreateContractorDto = {
  businessName: string;
  licenseNumber: string;
  firstName?: string;
  lastName?: string;
  streetLineOne?: string;
  streetLineTwo?: string;
  city: string;
  state: string;
  zip?: string;
  country?: string;
  phone: string;
  email: string;
  website?: string;
  pointOfContactName?: string;
  pointOfContactEmail?: string;
  pointOfContactPhone?: string;
  notes?: string;
  preferred: boolean;
  isActive: boolean;
  abilities: (
    | "Woodwork"
    | "Metal Work"
    | "Pergolas - Composite, Metal, Kits"
    | "Decking - Composite"
    | "Lighting - Low Voltage"
    | "Masonry"
    | "Turf"
    | "Pavers"
    | "Concrete"
    | "Outdoor Kitchens"
    | "Outdoor Showers"
    | "Water Features/Ponds"
    | "CA Rooms"
    | "Pools/Spas - Custom"
    | "Pools/Spas - Pre Fab"
    | "Sheds/ADUs"
    | "Botanical Only"
    | "Exterior"
  )[];
  coordinates: {
    lat: number;
    lon: number;
  };
};
export type Contractor = {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  businessName: string;
  licenseNumber: string;
  firstName?: string;
  lastName?: string;
  streetLineOne?: string;
  streetLineTwo?: string;
  city: string;
  state: string;
  zip?: string;
  country?: string;
  phone: string;
  email: string;
  website?: string;
  pointOfContactName?: string;
  pointOfContactEmail?: string;
  pointOfContactPhone?: string;
  notes?: string;
  preferred: boolean;
  isActive: boolean;
  abilities: (
    | "Woodwork"
    | "Metal Work"
    | "Pergolas - Composite, Metal, Kits"
    | "Decking - Composite"
    | "Lighting - Low Voltage"
    | "Masonry"
    | "Turf"
    | "Pavers"
    | "Concrete"
    | "Outdoor Kitchens"
    | "Outdoor Showers"
    | "Water Features/Ponds"
    | "CA Rooms"
    | "Pools/Spas - Custom"
    | "Pools/Spas - Pre Fab"
    | "Sheds/ADUs"
    | "Botanical Only"
    | "Exterior"
  )[];
  coordinates: {
    lat: number;
    lon: number;
  };
};
export type UpdateContractorDto = {
  businessName?: string;
  licenseNumber?: string;
  firstName?: string;
  lastName?: string;
  streetLineOne?: string;
  streetLineTwo?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  phone?: string;
  email?: string;
  website?: string;
  pointOfContactName?: string;
  pointOfContactEmail?: string;
  pointOfContactPhone?: string;
  notes?: string;
  preferred?: boolean;
  isActive?: boolean;
  abilities?: (
    | "Woodwork"
    | "Metal Work"
    | "Pergolas - Composite, Metal, Kits"
    | "Decking - Composite"
    | "Lighting - Low Voltage"
    | "Masonry"
    | "Turf"
    | "Pavers"
    | "Concrete"
    | "Outdoor Kitchens"
    | "Outdoor Showers"
    | "Water Features/Ponds"
    | "CA Rooms"
    | "Pools/Spas - Custom"
    | "Pools/Spas - Pre Fab"
    | "Sheds/ADUs"
    | "Botanical Only"
    | "Exterior"
  )[];
  coordinates: {
    lat: number;
    lon: number;
  };
};
export type LeadDto = {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  bsatScore: number;
  contractorStatus?: "yes" | "no" | "no response";
  contractorStatusUpdatedAt?: string;
  reasonContractorDeclined?: string;
  clientStatus?: "yes" | "no" | "no response";
  clientStatusUpdatedAt?: string;
  reasonClientDeclined?: string;
  siteWalkScheduledStatus?: "yes" | "no" | "no response";
  siteWalkScheduledStatusUpdatedAt?: string;
  siteWalkScheduledDate?: string;
  reasonSiteWalkNotScheduled?: string;
  siteWalkCompletedStatus?: "yes" | "no" | "no response";
  siteWalkCompletedStatusUpdatedAt?: string;
  reasonSitewalkNotCompleted?: string;
  revisionsRequestedStatus?: "yes" | "no" | "no response";
  revisionsRequestedStatusUpdatedAt?: string;
  revisionsRequested?: string;
  revisionsCompletedAt?: string;
  estimateSentStatus?: "yes" | "no" | "no response";
  estimateSentStatusUpdatedAt?: string;
  reasonEstimateNotDelivered?: string;
  estimateAmount?: number | null;
  contractSignedStatus?: "yes" | "no" | "no response";
  contractSignedStatusUpdatedAt?: string;
  contractAmount?: number | null;
  projectStartedStatus?: "yes" | "no" | "no response";
  projectStartedStatusUpdatedAt?: string;
  projectExpectedStartDate?: string;
  projectCompletedStatus?: "yes" | "no" | "no response";
  projectCompletedStatusUpdatedAt?: string;
  projectExpectedCompletionDate?: string;
  projectAmount?: number | null;
  projectExpectedPaymentDate?: string;
  invoicePaidStatus?: "yes" | "no" | "no response";
  invoicePaidStatusUpdatedAt?: string;
  invoiceAmount?: number | null;
  contractorId: number;
  buildProjectId: number;
};
export type CreateLeadDto = {
  contractorId: number;
  buildProjectId: number;
};
export type LeadStatusChange = {
  changes: object;
  changeType:
    | "Projects for Prospect"
    | "Ready for Pro Match"
    | "Waiting for Build Confirm"
    | "Sent for Review"
    | "Intro Sent"
    | "Site Walk Scheduled"
    | "Site Walk Completed"
    | "Revisions Requested"
    | "Estimate Delivered"
    | "Contract Signed"
    | "Project Started"
    | "Project Completed"
    | "Ready for Invoice"
    | "Invoice Sent"
    | "Invoice Paid"
    | "Client Unresponsive"
    | "Lost"
    | "Hold";
};
export type UpdateLeadDto = {
  bsatScore?: number;
  leadStatusChanges?: LeadStatusChange[];
  contractorId?: number;
  buildProjectId?: number;
};
export type CreateBuildProjectDto = {
  profileId: string;
  buildStage: string;
  revisionId?: string;
  projectId?: string;
};
export type BuildProjectDto = {
  id: number;
  createdAt: string;
  updatedAt: string;
  profileId: string;
  firstName: string;
  lastName: string;
  email: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  coordinates: {
    lat: number;
    lon: number;
  };
  budget: number;
  adjustedBudget: number;
  buildStage: string;
  revisionId: string;
  projectId: string;
};
export type UpdateBuildProjectDto = {
  profileId?: string;
  buildStage?: string;
  revisionId?: string;
  projectId?: string;
};
export const {
  useLiisaControllerHealthcheckQuery,
  useAccountManagersControllerCreateMutation,
  useAccountManagersControllerFindAllQuery,
  useAccountManagersControllerFindOneQuery,
  useAccountManagersControllerUpdateMutation,
  useAccountManagersControllerRemoveMutation,
  useClientProfilesControllerFindAllQuery,
  useContractorsControllerCreateMutation,
  useContractorsControllerFindAllQuery,
  useContractorsControllerFindOneQuery,
  useContractorsControllerUpdateMutation,
  useContractorsControllerRemoveMutation,
  useContractorsControllerGetLeadsByContractorQuery,
  useLeadsControllerCreateMutation,
  useLeadsControllerFindAllQuery,
  useLeadsControllerFindOneQuery,
  useLeadsControllerUpdateMutation,
  useLeadsControllerRemoveMutation,
  useBuildProjectsControllerCreateMutation,
  useBuildProjectsControllerFindAllQuery,
  useBuildProjectsControllerFindOneQuery,
  useBuildProjectsControllerUpdateMutation,
  useBuildProjectsControllerRemoveMutation,
  useBuildProjectsControllerGetLeadsByBuildProjectQuery,
} = injectedRtkApi;
