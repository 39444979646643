import React, { FC, useEffect, useState } from "react";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "state/slices/session/sessionSlice";
import { Box, Button, TextField, Typography } from "@mui/material";
import { initializeFirebase } from "utils/initializeFirebase";

export interface AuthPageProps {}

initializeFirebase();

const auth = getAuth();

export const AuthPage: FC<AuthPageProps> = (props) => {
  const session = useSelector(selectCurrentUser);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const em = localStorage.getItem("em");
    if (isSignInWithEmailLink(auth, window.location.href) && em) {
      signInWithEmailLink(auth, em);
    }
  }, []);

  return (
    <div>
      {!!(
        isSignInWithEmailLink(auth, window.location.href) && !session?.email
      ) ? (
        <Box>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                await signInWithEmailLink(auth, email);
                localStorage.set("em", email);
              } catch (error) {
                setError((error as any)?.message ?? "That didn't work");
              }
            }}
          >
            <TextField
              onChange={(e) => setEmail(e.currentTarget.value)}
              value={email}
              placeholder="EMAIL"
              helperText={error}
              error={!!error}
            />
            <Button type="submit">SUBMIT</Button>
          </form>
        </Box>
      ) : (
        <Typography>No sign in link detected</Typography>
      )}
      <pre>
        <code>{JSON.stringify(session)}</code>
      </pre>
    </div>
  );
};
